import React, {useContext, useState} from "react";
import "./componentStyle.scss";
import {GeneralContext} from "../contexts/GeneralContext";
import {useMediaQuery} from "react-responsive";
import AppHeader from "./AppHeader";
import LandingPage from "./LandingPage";
import KeepSnapping from "./KeepSnapping";
import ShareMore from "./ShareMore";
import ThankYouPage from "./ThankYouPage";
import ViewShares from "./ViewShares";
import TermsAndConditions from "./TermsAndConditions";
import FAQ from "./FAQ";
import StyledSnackbar from "../shared-components/generic/StyledSnackbar";
import SecondTierThankYouPage from "./gamificationResultPages/SecondTierThankYouPage";
import ImprovementsNeeded from "./gamificationResultPages/ImprovementsNeeded";
// import SwipeListener from "../generic/SwipeListener";

const Main = () => {
    const {
        uploadStage,
        errorMsg,
        setErrorMsg,
    } = useContext(GeneralContext);
    const isLandscape = useMediaQuery({query: "(orientation: landscape)"});

    const renderUploadPage = (stage) => {
        switch (stage) {
            case 1:
                return <LandingPage/>
            case 2:
                return <KeepSnapping/>
            case 3:
                return <ShareMore/>
            case 4:
                return <ThankYouPage/>
            case 5:
                return <ViewShares/>
            case 6:
                return <TermsAndConditions/>
            case 7:
                return <FAQ/>
            case 8:
                return <SecondTierThankYouPage/>
            case 9:
                return <ImprovementsNeeded/>
            default:
                return null
        }
    }

    return (
        // <SwipeListener returnToStage={returnToStage} setUploadStage={setUploadStage}>
            <div className={`main middle-text${isLandscape ? " landscape" : ""}`}>
                {uploadStage > 1
                    ? <AppHeader/>
                    : null
                }
                {renderUploadPage(uploadStage)}
                <StyledSnackbar
                    open={!!errorMsg}
                    onClose={() => setErrorMsg("")}
                    autoHideDuration={4500}
                    type="error"
                    message={errorMsg}
                />
            </div>
        // </SwipeListener>
    );
};

export default Main;