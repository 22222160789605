import React, {useContext} from 'react';
import {GeneralContext} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import {Utility} from "../../shared-components/Utility";
import improvementsNeededMedia from "../../assets/improvements-needed-media.png";
import StyledButton from "../../shared-components/generic/StyledButton";
import {renderTips} from "../KeepSnapping";

const ImprovementsNeededPage = () => {
    const {
        appLang,
        thankYouDts,
        industryCode,
        goToNewStage,
    } = useContext(GeneralContext);

    const content = language[appLang];

    return (
        <div className="thank-you-page">
            <div className="thank-you-date additional-text">
                {Utility.capitalize(content.TODAYS_DATE)}: {Utility.renderDate_MMDDYYY(thankYouDts)}
            </div>
            <div className="thank-you-media-container">
                <img
                    className="thank-you-media"
                    src={improvementsNeededMedia}
                    alt={content.IMAGE_OF_UNHAPPY_FACE_WITH_IMPROVEMENTS_NEEDED_MESSAGE}
                />
            </div>
            <div className="main-container improvements-needed-page">
                <div className="big-text">
                    <div>{Utility.capitalize(content.THANKS_FOR_SHARING)}!</div>
                    <div>{Utility.capitalize(content.CONTENT_NEEDS_FLAVOR_SENTENCE)} <span className="emoji">🌶️</span>
                    </div>
                </div>
                <div className="btn-container gamification-mode">
                    <StyledButton
                        type="main"
                        onClick={() => goToNewStage(1, 2)}
                    >
                        {Utility.capitalize(content.TRY_IT_AGAIN)}!
                    </StyledButton>
                </div>
                <div className="try-again-message hint">
                    <div className="variable-sentence">
                        {Utility.capitalize(content.REMEMBER_THE_BETTER_THE_QUALITY_SENTENCE)}! <span className="emoji">🎁</span>
                    </div>
                    {renderTips(content, industryCode, true)}
                    <div className="small-text-regular">{Utility.capitalize(content.GIVE_IT_ANOTHER_SHOT_SENTENCE)} <span className="emoji">🚀</span></div>
                </div>
            </div>
        </div>
    );
};

export default ImprovementsNeededPage;