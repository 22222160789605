import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import StyledUpload from "../shared-components/generic/StyledUpload";
import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import StyledCheckbox from "../shared-components/generic/StyledCheckbox";
import StyledButton from "../shared-components/generic/StyledButton";
import {ModalContext} from "../contexts/ModalContext";

export const renderTips = (content, industryCode) => {
    return (
        <div className="additional-text hint tips-container">
            <div>{Utility.capitalize(content.TRY_THESE_TIPS)}:</div>
            <ul>
                <li> <span className="emoji">😍</span> {Utility.capitalize(content.CAPTURE_FUN_VIBRANT_AND_GENUINE_MOMENTS)}!</li>
                {!industryCode
                    ? <li> <span className="emoji">🍲</span> {Utility.capitalize(content.SHOW_FRESH_MOUTHWATERING_DISHES)}</li>
                    : null
                }
                <li> <span className="emoji">📹</span> {Utility.capitalize(content.A_VIDEO_IS_WORTH_A_THOUSAND_PHOTOS)}!</li>
                <li> <span className="emoji">🔍</span> {Utility.capitalize(content.MAKE_SURE_YOUR_VISUALS_ARE_CLEAR)}</li>
            </ul>
        </div>
    )
}

const KeepSnapping = () => {
    const {
        appLang,
        currentUploads,
        hasAcceptedTerms,
        hasBeenAskedToShareVideo,
        setUploadStage,
        setReturnToStage,
        setCurrentUploads,
        setHasAcceptedTerms,
        goToShareMore,
        industryCode,
        gamificationActive,
        // checkUploadsIncludeVideos,
    } = useContext(GeneralContext);
    const {
        setVideoNudgeModalOpen,
        setUploadMediaModalOpen,
        setFromLandingPage
    } = useContext(ModalContext);
    const content = language[appLang];

    const handleDeleteImage = (index) => {
        const updatedCurrentUploads = [...currentUploads];
        const imageToDelete = updatedCurrentUploads[index];
        const urlToRevoke = imageToDelete.tempUrl;
        Utility.deleteMediaFromStorage(imageToDelete.filePath).catch((e) => console.error(e));

        updatedCurrentUploads.splice(index, 1);
        setCurrentUploads(updatedCurrentUploads);

        URL.revokeObjectURL(urlToRevoke);
    }

    const renderCurrentUploads = () => {
        const mediaToRender = [];
        currentUploads.forEach((uploadObj, index) => {
            mediaToRender.push(
                <div className="media-box" key={`media-upload-${index}`}>
                    {Utility.renderMedia(uploadObj.tempUrl, `Uploaded media ${index + 1}`, uploadObj.metaData.fileExt, "current-upload")}
                    <IconButton onClick={() => handleDeleteImage(index)}>
                        <DeleteForever/>
                    </IconButton>
                </div>
            )
        });

        return mediaToRender;
    }

    const handleInitiateUpload = () => {
        setFromLandingPage(false);
        setUploadMediaModalOpen(true);
    }

    const goToTermsAndConditions = () => {
        setReturnToStage(2);
        setUploadStage(6);
    }

    const getTermsAndConditionsLabel = () => {
        const variableMap = new Map();
        const createTermsAndConditionsLink = () => {
            return (
                <div className="styled-link" onClick={goToTermsAndConditions}>
                    <span>{content.TERMS_AND_CONDITIONS}</span>
                    <div className="link-underline"/>
                </div>
            )
        };
        variableMap.set("termsAndConditions", createTermsAndConditionsLink());
        return Utility.createVariableSentenceSpan(Utility.capitalize(content.I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$), variableMap);
    }

    const handleGoToNext = () => {
        //TODO re-enable check for video - or gamify the process
        const hasVideoUpload = true//checkUploadsIncludeVideos();

        if (!hasVideoUpload && !hasBeenAskedToShareVideo) {
            setVideoNudgeModalOpen(true);
        } else goToShareMore();
    }

    return (
        <div className="main-container keep-snapping with-header">
            <div className="image-gallery">
                {renderCurrentUploads()}
                <StyledUpload
                    onClick={handleInitiateUpload}
                />
            </div>

            <div className="small-text-regular hint">
                {gamificationActive
                    ? <div className="reward-sentence">
                        {Utility.capitalize(content.THE_BETTER_THE_QUALITY_SENTENCE)}! <span className="emoji">🎁</span>
                    </div>
                    : null
                }
                {renderTips(content, industryCode)}
            </div>
            <div className="bottom-section">
                <StyledCheckbox
                    className="additional-text"
                    checked={hasAcceptedTerms}
                    onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
                    label={getTermsAndConditionsLabel()}
                />
                <StyledButton
                    type="main"
                    onClick={handleGoToNext}
                    isDisabled={!hasAcceptedTerms || currentUploads.length < 1}
                >
                    {Utility.capitalize(content.NEXT)}
                </StyledButton>
            </div>
        </div>
    )
};

export default KeepSnapping;