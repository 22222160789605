import './appStyle.scss';
import {Box} from "@mui/material";
import Main from "./components/Main";
import {Suspense, useContext, useEffect} from "react";
import {routes, GeneralContext} from "./contexts/GeneralContext";
import NotFound from "./components/NotFound";
import {ConstantLoaderModal} from "./components/modals/LoaderModal";
import ModalContainer from "./components/modals/ModalContainer";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import RedeemPromoMain from "./components/promoCodeRedemption/RedeemPromoMain";

function App() {
    const {
        brandIsDoingCgc,
        setContentLoading,
        clearTempMedia,
        getBrandData,
    } = useContext(GeneralContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const brandId = urlSearchParams.get('id');

    useEffect(() => {
        getBrandData(brandId).then(() => {
            setContentLoading(false);
        });
        return () => clearTempMedia();
    }, []);

    return (
        <div className="app small-text-regular">
            <Box className="app-container">
                {brandIsDoingCgc
                    ? <Router>
                        <Routes>
                            <Route exact path={routes.ROOT} element={<Main/>}/>
                            <Route exact path={routes.PROMO_VALIDATION} element={<RedeemPromoMain/>}/>
                            <Route path={routes.WILDCARD} element={<Navigate to={routes.NOT_FOUND}/>}/>
                            <Route path={routes.NOT_FOUND} element={<NotFound/>}/>
                        </Routes>
                    </Router>
                    : <NotFound/>
                }
            </Box>
            <Suspense fallback={ConstantLoaderModal}>
                <ModalContainer/>
            </Suspense>
        </div>
    );
}

export default App;
