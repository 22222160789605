import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import quickTipsImg from "../assets/quick-tips.png";

const QuickTips = () => {
    const {
        appLang
    } = useContext(GeneralContext);
    const content = language[appLang];

    return (
        <div className="quick-tips-container additional-text">
            <div className="quick-tips-img">
                <img
                    className="quick-tips-img"
                    src={quickTipsImg}
                    alt={content.QUICK_TIPS}
                />
            </div>
            <div className="quick-tip-box">
                <div className="quick-tip-header">{Utility.capitalize(content.SPICE_IT_UP)}</div>
                <div className="quick-tip-content">{Utility.capitalize(content.CAPTURE_FUN_VIBRANT_AND_GENUINE_MOMENTS)}!</div>
            </div>
            <div className="quick-tip-box">
                <div className="quick-tip-header">{Utility.capitalize(content.VIDEOS_PLUS_PHOTOS)}</div>
                <div className="quick-tip-content">{Utility.capitalize(content.A_VIDEO_IS_WORTH_A_THOUSAND_PHOTOS)}!</div>
            </div>
            <div className="quick-tip-box">
                <div className="quick-tip-header">{Utility.capitalize(content.MAKE_IT_CLEAR)}</div>
                <div className="quick-tip-content">{Utility.capitalize(content.SO_YOUR_MOMENTS_REALLY_SHINE)}!</div>
            </div>
        </div>
    )
}

export default QuickTips;