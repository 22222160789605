import React, {useContext} from 'react';
import {GeneralContext} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import {Utility} from "../../shared-components/Utility";
import secondTierThankYouMedia from "../../assets/second-tier-thank-you-media.png";
import StyledButton from "../../shared-components/generic/StyledButton";
import RenderRedeemQrCode from "./RenderRedeemQrCode";

const SecondTierThankYouPage = () => {
    const {
        appLang,
        thankYouDts,
        brandId,
        goToViewMyShares,
        goToNewStage,
        validationCode,
    } = useContext(GeneralContext);

    const content = language[appLang];

    return (
        <div className="thank-you-page">
            <div className="thank-you-date additional-text">
                {Utility.capitalize(content.TODAYS_DATE)}: {Utility.renderDate_MMDDYYY(thankYouDts)}
            </div>
            <div className="thank-you-media-container">
                <img
                    className="thank-you-media"
                    src={secondTierThankYouMedia}
                    alt={content.IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA}
                />
                {!!validationCode
                    ? <RenderRedeemQrCode validationCode={validationCode} brandId={brandId}/>
                    : null
                }
            </div>
            <div className="main-container">
                <div className="big-text">
                    <div className="bold">{Utility.capitalize(content.THANKS_FOR_SHARING)}!</div>
                    <div>{Utility.capitalize(content.TO_CLAIM_YOUR_REWARD)}:</div>
                    <div>{content.TAKE_A_SCREENSHOT_SENTENCE}</div>
                </div>
                <div className="additional-text hint">
                    {content.WANT_TO_AIM_HIGHER_PARAGRAPH} <span className="emoji">😍</span>
                </div>
                <div className="btn-container gamification-mode">
                    <StyledButton
                        type="main"
                        onClick={() => goToNewStage(1, 2)}
                    >
                        {Utility.capitalize(content.TRY_IT_AGAIN)}!
                    </StyledButton>
                    <StyledButton
                        type="secondary"
                        onClick={() => goToViewMyShares(8, 5)}
                    >
                        {Utility.capitalize(content.IM_DONE_FOR_NOW)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default SecondTierThankYouPage;