import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import {IconButton} from "@mui/material";
import {CaretLeft} from "@phosphor-icons/react";

//TODO convert all stage numbers to enums!

const AppHeader = ({isCodeRedemption, resultQuality}) => {
    const {
        appLang,
        uploadStage,
        returnToStage,
        setUploadStage,
        setReturnToStage,
        goToShareMore,
        goToViewMyShares,
        goToNewStage,
        handleSubmitData,
        getResultPageFromProcessedId,
    } = useContext(GeneralContext);
    const content = language[appLang];

    const renderTitle = () => {
        let titleText;
        switch (uploadStage) {
            case 2:
                titleText = Utility.capitalize(content.KEEP_SNAPPING)
                break;
            case 3:
                titleText = Utility.capitalize(content.SHARE_MORE_WITH_US_OPTIONAL)
                break;
            case 4:
            case 8:
            case 9:
                titleText = Utility.capitalize(content.THANK_YOU)
                break;
            case 5:
                titleText = Utility.capitalize(content.VIEW_MY_SHARES)
                break;
            case 6:
                titleText = Utility.capitalize(content.TERMS_AND_CONDITIONS)
                break;
            case 7:
                titleText = Utility.capitalize(content.FAQ)
                break;
            default:
                titleText = "";
                break
        }

        return (
            <div className="additional-text">
                {titleText}
            </div>
        )
    }

    const renderNextAction = () => {
        switch (uploadStage) {
            case 2:
                return <div className="header-link" onClick={goToShareMore}>
                    {Utility.capitalize(content.NEXT)}
                </div>
            case 3:
                return <div className="header-link" onClick={handleSubmitData}>
                    {Utility.capitalize(content.DONE)}
                </div>
            case 4:
                return <div className="header-link" onClick={goToViewMyShares}>
                    {Utility.capitalize(content.NEXT)}
                </div>
            case 8:
                return <div className="header-link" onClick={() => goToViewMyShares(8, 5)}>
                    {Utility.capitalize(content.NEXT)}
                </div>
            case 9:
                return <div className="header-link" onClick={() => goToViewMyShares(9, 5)}>
                    {Utility.capitalize(content.NEXT)}
                </div>
            case 5:
                return <div className="header-link" onClick={() => {
                    goToNewStage(3, getResultPageFromProcessedId());
                }}>
                    {Utility.capitalize(content.DONE)}
                </div>
            default:
                return null;
        }
    }

    const handleGoBack = () => {
        let newReturnToStage;
        switch (returnToStage) {
            case 2:
                newReturnToStage = 1;
                break;
            case 3:
                newReturnToStage = 2;
                break;
            case 4:
            case 8:
            case 9:
                newReturnToStage = 3;
                break;
            case 5:     //is case 5 being used at all?
                newReturnToStage = getResultPageFromProcessedId();
                break;
            default:
                newReturnToStage = 0;
        }

        setUploadStage(returnToStage);
        setReturnToStage(newReturnToStage);
    }

    return (
        <div className="app-header">
            {isCodeRedemption
                ? <div className="header-content">
                    <div/>
                    {!resultQuality
                        ? <div className="additional-text">
                            {Utility.capitalize(content.REDEEM_AWARD)}
                        </div>
                        : <div className="additional-text">
                            {Utility.capitalize(content[resultQuality === "toptier" ? "REDEEM_FIRST_TIER_REWARD" : "REDEEM_SECOND_TIER_REWARD"])}
                        </div>
                    }
                    <div/>
                </div>
                : <div className="header-content">
                    <IconButton
                        onClick={handleGoBack}
                    >
                        <CaretLeft/>
                    </IconButton>
                    {renderTitle()}
                    {renderNextAction()}
                </div>
            }
            <div className="dividing-line"/>
        </div>

    );
};

export default AppHeader;