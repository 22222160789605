import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import thankYouMedia from "../assets/thank-you-media.png";
import firstTierThankYouMedia from "../assets/first-tier-thank-you-media.png";
import StyledButton from "../shared-components/generic/StyledButton";
import RenderRedeemQrCode from "./gamificationResultPages/RenderRedeemQrCode";

const ThankYouPage = () => {
    const {
        appLang,
        thankYouDts,
        brandId,
        industryCode,
        hasMainRewardAsRaffle,
        thankYouImageUrl,
        userEmail,
        userHandle,
        goToViewMyShares,
        goToNewStage,
        gamificationActive,
        validationCode,
    } = useContext(GeneralContext);
    const content = language[appLang];

    const showForgotToAddContact = !userEmail && !userHandle;

    return (
        <div className="thank-you-page">
            <div className="thank-you-date additional-text">
                {Utility.capitalize(content.TODAYS_DATE)}: {Utility.renderDate_MMDDYYY(thankYouDts)}
            </div>
            <div className="thank-you-media-container">
                <img
                    className="thank-you-media"
                    src={gamificationActive
                        ? firstTierThankYouMedia
                        : !!thankYouImageUrl
                            ? thankYouImageUrl
                            : thankYouMedia
                    }
                    alt={content.IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA}
                />
                {gamificationActive && !!validationCode
                    ? <RenderRedeemQrCode validationCode={validationCode} brandId={brandId}/>
                    : null
                }
            </div>
            <div className="main-container">
                <div className="big-text">
                    {gamificationActive
                        ? <>
                            <div className="bold">{Utility.capitalize(content.THANKS_AND_CONGRATS)}!</div>
                            <div>{Utility.capitalize(content.TO_CLAIM_YOUR_REWARD)}:</div>
                            <div>{content.TAKE_A_SCREENSHOT_SENTENCE}</div>
                        </>
                        : !!hasMainRewardAsRaffle
                            ? <span>{Utility.capitalize(content[showForgotToAddContact ? "DONT_MISS_YOUR_CHANCE_TO_WIN" : "CONTACT_ENTERED_INTO_RAFFLE_SENTENCE"])}</span>
                            : <span>{Utility.capitalize(content[!industryCode ? "GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US" : "GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US_ALT"])}</span>
                    }

                </div>
                {!!hasMainRewardAsRaffle && showForgotToAddContact
                    ? <div
                        className="forgot-to-add-contact additional-text hint styled-link"
                        onClick={() => goToNewStage(2, 3)}
                    >
                        <span>{Utility.capitalize(content.SHARE_YOUR_CONTACT_INFO_TO_ENTER_RAFFLE)}</span>
                        <div className="link-underline"/>
                    </div>
                    : null
                }
                <div className="btn-container">
                    <StyledButton
                        type="main"
                        onClick={goToViewMyShares}
                    >
                        {Utility.capitalize(content.NEXT)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage;