import React from "react";
import {QRCodeSVG} from "qrcode.react";

const RenderRedeemQrCode = ({brandId, validationCode}) => {
    return (
        <QRCodeSVG
            className="qr-code"
            renderas="svg"
            value={`https://${window.location.host}/redeem-code/?id=${brandId}&code=${validationCode}`}
            size={256}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="H"
        />
    )
};

export default RenderRedeemQrCode;