import React, {useEffect, useState, useContext} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {Utility} from "../../shared-components/Utility";
import {language} from "../../shared-components/content/language";
import {useMediaQuery} from "react-responsive";
import AppHeader from "../AppHeader";
import firstTierThankYouMedia from "../../assets/first-tier-thank-you-media.png";
import secondTierThankYouMedia from "../../assets/second-tier-thank-you-media.png";
import StyledButton from "../../shared-components/generic/StyledButton";

const RedeemPromoMain = () => {
    const {
        appLang,
        setErrorMsg,
        setContentLoading,
    } = useContext(GeneralContext);
    const content = language[appLang];
    const isLandscape = useMediaQuery({query: "(orientation: landscape)"});

    const [redemptionCodeLoading, setRedemptionCodeLoading] = useState(true);
    const [resultQuality, setResultQuality] = useState("");
    const [redemptionCode, setRedemptionCode] = useState("");
    const [redemptionSuccessful, setRedemptionSuccessful] = useState(false);
    const [isCodeAlreadyRedeemed, setIsCodeAlreadyRedeemed] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeToValidate = urlSearchParams.get('code');

    useEffect(() => {
        setRedemptionCodeLoading(true);
        Utility.httpCall("retrievePromoCodeDetails", {codeToValidate}).then((response) => {
            if (!!response?.data) {
                setResultQuality(response.data.quality);
                setRedemptionCode(response.data.code || "");
                setIsCodeAlreadyRedeemed(!!response.data.validated);
                setRedemptionCodeLoading(false);
            } else setErrorMsg(Utility.capitalize(content.ERROR_VALIDATING_CODE))
        });
    }, [codeToValidate]);

    const redeemCode = async () => {
        setContentLoading(true);
        const response = await Utility.httpCall("validatePromoCode", {codeToValidate});
        if (!!response?.data) {
            setRedemptionSuccessful(true);
        } else setRedemptionSuccessful(false);
        setContentLoading(false);
    }

    const renderRewardRedemption = () => {
        if (redemptionCodeLoading) {
            return null
        } else return (
            <div className="thank-you-page reward-redemption">
                <div className="thank-you-media-container">
                    <img
                        className="thank-you-media"
                        src={resultQuality === "toptier" ? firstTierThankYouMedia : secondTierThankYouMedia}
                        alt={content.IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA}
                    />
                </div>
                <div className="main-container">
                    <div className="big-text">
                        {Utility.capitalize(content[resultQuality === "toptier" ? "FIRST_TIER_REWARD" : "SECOND_TIER_REWARD"])}
                    </div>
                    <div className="minor-text additional-text">
                        {Utility.capitalize(content.UNIQUE_CODE)}: {redemptionCode}
                    </div>
                    <div className="btn-container">
                        <StyledButton
                            type="main"
                            isDisabled={redemptionSuccessful}
                            onClick={redeemCode}
                        >
                            {Utility.capitalize(content[redemptionSuccessful ? "REDEEMED" : "COMPLETE_REDEMPTION"])}
                        </StyledButton>
                    </div>
                </div>
            </div>
        )
    }

    const renderRewardAlreadyRedeemed = () => {
        if (redemptionCodeLoading) {
            return null
        } else return (
            <div className="thank-you-page reward-redemption">
                <div className="main-container">
                    <div className="big-text">
                        {Utility.capitalize(content.REWARD_REDEEMED_ALREADY)}
                    </div>
                    <div className="minor-text additional-text">
                        {Utility.capitalize(content.UNIQUE_CODE)}: {redemptionCode}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`main middle-text${isLandscape ? " landscape" : ""}`}>
            <AppHeader isCodeRedemption={true} resultQuality={isCodeAlreadyRedeemed ? "" : resultQuality}/>
            {isCodeAlreadyRedeemed
                ? renderRewardAlreadyRedeemed()
                : renderRewardRedemption()
            }
        </div>
    );
};

export default RedeemPromoMain;