import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import enums from '../enums.json';
import {Utility} from "../shared-components/Utility";
import StyledButton from "../shared-components/generic/StyledButton";
import StyledInput from "../shared-components/generic/StyledInput";
import StyledSelect from "../shared-components/generic/StyledSelect";

const {howHeardAboutUsEnum} = enums;

const ShareMore = () => {
    const {
        appLang,
        userEmail,
        userHandle,
        incentiveStatement,
        secondIncentive,
        industryCode,
        howHeardAboutUsResponse,
        hasMainRewardAsRaffle,
        setUploadStage,
        setReturnToStage,
        setUserEmail,
        setUserHandle,
        setHowHeardAboutUsResponse,
        handleSubmitData,
        gamificationActive,
    } = useContext(GeneralContext);
    const content = language[appLang];

    const goToFAQ = () => {
        setReturnToStage(3);
        setUploadStage(7);
    }

    const getFAQSentence = () => {
        const variableMap = new Map();
        const createFAQLink = () => {
            return (
                <div className="styled-link" onClick={goToFAQ}>
                    <span>{content.FAQ}</span>
                    <div className="link-underline"/>
                </div>
            )
        };
        variableMap.set("faq", createFAQLink());
        return Utility.createVariableSentenceSpan(Utility.capitalize(content.QUESTIONS_CHECK_OUT_OUR_$$FAQ$$), variableMap);
    }

    return (
        <div className="main-container share-more with-header">
            <div className="input-container">
                <span className="small-text-regular">{!!hasMainRewardAsRaffle
                    ? Utility.capitalize(content.SHARE_SOCIAL_HANDLE_FOR_RAFFLE_SENTENCE)
                    : !!secondIncentive
                        ? Utility.capitalize(content[!industryCode ? "SHARE_SOCIAL_HANDLE_MONTHLY_WINNER_SENTENCE" : "SHARE_SOCIAL_HANDLE_MONTHLY_WINNER_SENTENCE_ALT"])
                        : Utility.capitalize(content[!industryCode ? "SHARE_SOCIAL_HANDLE_NO_MONTHLY_WINNER_SENTENCE" : "SHARE_SOCIAL_HANDLE_NO_MONTHLY_WINNER_SENTENCE_ALT"])
                }!</span>
                <StyledInput
                    value={userHandle}
                    onChange={(e) => setUserHandle(e.target.value)}
                    placeholder={Utility.capitalize(content.SOCIAL_MEDIA_HANDLE)}
                />
            </div>
            <div className="input-container">
                <span className="small-text-regular">{!!hasMainRewardAsRaffle
                    ? Utility.createVariableSentenceSpan(Utility.capitalize(content.SHARE_YOUR_CONTACT_DETAILS_TO_$$CGC_INCENTIVE$$), new Map([["cgcIncentive", incentiveStatement.toLowerCase()]]))
                    : Utility.capitalize(content.WANT_TO_TEAM_UP_SENTENCE)
                }</span>
                <StyledInput
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    placeholder={Utility.capitalize(content.YOUR_PHONE_NUMBER_OR_EMAIL)}
                />
            </div>
            <div className="input-container">
                <span className="small-text-regular">{Utility.capitalize(content.HOW_DID_YOU_HEAR_ABOUT_US)}</span>
                <StyledSelect
                    className="additional-text"
                    value={howHeardAboutUsResponse}
                    options={Object.keys(howHeardAboutUsEnum).map((howHeardAboutUsKey) => {
                        return {
                            value: howHeardAboutUsEnum[howHeardAboutUsKey],
                            label: Utility.capitalize(content[howHeardAboutUsKey])
                        }
                    })}
                    onChange={(selectedResponse) => setHowHeardAboutUsResponse(selectedResponse)}
                    placeholder={Utility.capitalize(content.SELECT)}
                />
            </div>

            <div className="bottom-section small-text-regular">
                {getFAQSentence()}
                <StyledButton
                    type="main"
                    onClick={handleSubmitData}
                >
                    {Utility.capitalize(content[gamificationActive ? "UNCOVER_MY_REWARDS" : "DONE"])}
                </StyledButton>
            </div>
        </div>
    );
};

export default ShareMore;