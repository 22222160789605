import React, {useContext} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import {Utility} from "../../shared-components/Utility";
import StyledNotification from "../../shared-components/generic/StyledNotification";

const ImagesRejectedNotification = () => {
    const {
        appLang,
        imagesRejectedNotificationOpen,
        setImagesRejectedNotificationOpen,
        goToNewStage,
        industryCode,
    } = useContext(GeneralContext)
    const content = language[appLang];

    const handleClose = () => {
        goToNewStage(1, 2);
        setImagesRejectedNotificationOpen(false);
    }

    const renderText = () => {
        return (
            <div className="additional-text images-rejected-text">
                {Utility.capitalize(content[industryCode === 0 ? "IMAGES_REJECTED_TEXT" : "IMAGES_REJECTED_ALTERNATE_TEXT"])}
            </div>
        )
    }

    return (
        <StyledNotification
            open={imagesRejectedNotificationOpen}
            onClose={handleClose}
            className="images-rejected-modal"
            renderNotification={renderText}
            acceptText={Utility.capitalize(content.GOT_IT)}
            acceptAction={handleClose}
        />
    )
}

export default ImagesRejectedNotification;